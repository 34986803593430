import { EncodedCookieDataStore } from "../../services/storage/datastore/EncodedCookieDataStore";
import { Base64EncoderService } from "../../services/storage/encoder/Base64EncoderService";
import { Base64DecoderService } from "../../services/storage/decoder/Base64DecoderService";

const initializeCookie = (name) => {
  const cookie = new EncodedCookieDataStore({
    cookieName: name,
    decoderService: new Base64DecoderService(),
    encoderService: new Base64EncoderService(),
  });

  return cookie;
};

export const getCookie = (name) => {
  const cookie = initializeCookie(name);
  return Object.freeze(cookie._getCookie());
};

export const removeCookies = () => {
  document.cookie.split(";").forEach(function (c) {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
};

export const removeCookie = (name) => {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

/**
 *
 * @param {String} cookie_name
 * @param {Object} params
 * @param {String} params.order
 * @param {String} params.reference
 * @param {String} params.pdfId
 * @param {String} params.transactionId
 * @param {String} params.urlRedirect
 * @param {String} params.gateway
 * @param {Object} params.regularPrice
 * @param {Object} params.application
 * @param {String} params.address_token
 * @param {String} params.utm_source
 * @param {String} params.utm_campaign
 * @param {String} params.utm_medium
 * @param {String} params.utm_content
 * @param {String} params.app_id
 * @param {String} params.pdfId
 *
 */
export const setCookies = (cookie_name, params) => {
  const cookies = initializeCookie(cookie_name);

  if (cookies && typeof params === "object" && Object.keys(params).length > 0) {
    Object.keys(params).forEach((key) => {
      cookies.write({ key: key, data: params[key] });
    });
  }
};
